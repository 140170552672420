import axios from "axios";

export const lot = {
  namespaced: true,
  state: () => ({
    lot: {
      attributes: {},
    },
    lotInAuction: null,
  }),
  mutations: {
    SET_LOT(state, lot) {
      state.lot = lot;
    },
    SET_LOT_IN_AUCTION(state, lot) {
      state.lotInAuction = lot;
    },
  },
  actions: {
    async fetchLot({ commit }, lot_id) {
      try {
        const { data } = (await axios.get(`/api/lots/${lot_id}`)).data;
        commit("SET_LOT", data);
        return data;
      } catch (e) {
        return e;
      }
    },

    async fetchLotInAuction({ commit }) {
      try {
        const { data } = (await axios.get(`/api/lots/show/inauction`)).data;
        commit("SET_LOT_IN_AUCTION", data);
        return data;
      } catch (e) {
        return e;
      }
    },

    async pushNewBid({ commit }, payload) {
      try {
        console.log(commit);
        await axios.post(`/api/users/new/bid`, { bid: payload });
        return true;
      } catch (error) {
        return error;
      }
    },

    async awardLot({ commit }) {
      try {
        const { message } = (await axios.post(`/api/lots/award/lot`)).data;
        commit("SET_LOT_IN_AUCTION", null);
        return message;
      } catch (error) {
        return error;
      }
    },
    async nextLot({ commit }, payload) {
      try {
        console.log(commit);
        const data = (await axios.get(`/api/lots/next/lot?auction_id=${payload}`)).data
        return data;
      } catch (error) {
        return error
      }
    },  
  },
  getters: {
    lotInAuction: (state) => state.lotInAuction,
    lot: (state) => state.lot,
  },
};
